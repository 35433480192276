.App {
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333333;
  color: white;
  height: 30;
  text-align: center;
  font-size: x-small;
}

nav {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-around;
  align-items: center;
  min-height: 5vh;
  background: #cccccc;
  color: 000;
}

.lightcontrol {
  margin: 20;
  padding-right: 30;
  overflow: "auto";
  height: "inherit";
  display: "block";
  padding-bottom: 100;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.Title {
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
  font-size: large;
}

.abc {
  margin: 1em;
}
